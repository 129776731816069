import Api from '@/composables/useApi'
import {ref, watch, onMounted, onUnmounted, toRef} from 'vue'
import { yearFilter, regionFilter, clubFilter } from '@/composables/useFilters'

const useAccResultatsWidget = () => {

  const loaded = ref(false)
  const watcherList = ref([])
  const voData = ref([])
  const hnData = ref([])
  const data = () => [voData.value, hnData.value]

  const selectedSeries = toRef([
    {
      all: true,
      selected: true,
      id: "VO",
      name: "Voile Olympique",
      parent: false,
      raw: { disciplineFamille: "VO" },
      children: []
    },
    {
      all: true,
      selected: true,
      id: "DHN",
      name: "Disciplines de Haut-Niveau",
      parent: false,
      raw: { disciplineFamille: "DHN" },
      children: []
    }
  ])

  const selectedCompetitions = toRef([
    {
      "hnNiveauId": "CM",
      "hnNiveauLibelle": "MONDE",
      "hnNiveauCategorie": "Senior",
      "hnNiveauOrdre": 30
    },
  ])

  const indicateurs = toRef([
    { id: 'titres',         name: 'Or',                 selected: true},
    { id: 'medailleArgent', name: 'Argent',             selected: true},
    { id: 'medailleBronze', name: 'Bronze',             selected: true},
  ])

  onMounted(async () => {

    const watchers = [
      yearFilter.singleYearFilter,
      regionFilter.selectedLigue,
      clubFilter.selectedPPFStructure,
    ]

    watchers.forEach((target) => {
      const watcher = watch(target, (value, oldValue) => {
        if(value !== oldValue) fetchResultats()
      })
      watcherList.value.push(watcher)
    })
    fetchResultats()
  })

  onUnmounted(() => {
    watcherList.value.forEach((unwatch) => unwatch())
  })

  function handleRequestBody(series) {
    const body = {
      series: {
        series : series.map((serie) => ({
          codeBateau: serie.raw.codeBateau || null,
          codeBateauLibell: serie.raw.codeBateauLibell || null,
          disciplineFamille: serie.raw.disciplineFamille || null,
          disciplineCategorie: serie.raw.disciplineCategorie || null,
          secteurCode: serie.raw.secteurCode || null,
          sexe: serie.raw.sexe || null
        }))
      },
      competitions: {
        competitions: selectedCompetitions.value.length
        ? [selectedCompetitions.value.map((el) => el.hnNiveauId).join(',')]
        : []
      },
      annees: {
        anneeDebut: yearFilter.singleYearFilter.value,
        anneeFin: yearFilter.singleYearFilter.value,
      },
      ages: {
        ageMin: null,
        ageMax: null,
      },
      collectifs: {
        collectifs: [""]
      }
    }
    
    if(regionFilter.selectedLigue.value)
      body.ligue = {
        ligue: regionFilter.selectedLigue.value.id
      }

    if(clubFilter.selectedPPFStructure.value)
      body.structures = {
        structureCodes: [clubFilter.selectedPPFStructure.value.code]
      }

    return body
  }

  async function fetchResultats() {
    try {
      loaded.value = false

      const voReq = selectedSeries.value.filter((el) => el.raw.disciplineFamille === "VO")
      if(voReq) {
        const voResponse = await Api.post('/stats/indicateursperformance/resultats', handleRequestBody(voReq))
        voData.value = handleResults("VO", "Voile Olympique", voResponse.data)
      }
      
      const hnReq = selectedSeries.value.filter((el) => el.raw.disciplineFamille === "DHN")
      if(hnReq) {
        const hnResponse = await Api.post('/stats/indicateursperformance/resultats', handleRequestBody(hnReq))
        hnData.value = handleResults("DHN", "Disciplines de Haut Niveau", hnResponse.data)
      }

    }
    catch (err) {
      console.error(err)
    }
    loaded.value = true
  }

  function handleResults(id, name, results) {
    const data = {
      id,
      name,
      competitions: [],
    }

    data.competitions = selectedCompetitions.value.map((compet) => ({
      id: compet.hnNiveauId,
      name: compet.hnNiveauLibelle,
      indicateurs: indicateurs.value.map((indic) => {

        const indicData = {
          name: indic.name
        }

        const targetCompet = results.filter((el) => el.hnNiveauId === compet.hnNiveauId)
        if(targetCompet.length) {
          if(indic.id === "meilleurePlace") {
            indicData.value = targetCompet.map((el) => el[indic.id]).sort((a, b) => a - b).at(0)
          }
          else {            
            indicData.value = results
              .filter((el) => el.hnNiveauId === compet.hnNiveauId)
              .reduce((acc, val) => acc + val[indic.id], 0)
          }
        }
        else {
          indicData.value = null
        }

        return indicData
      })
    }))

    return data
  }

  return {
    loaded,
    data,
  }
}

export default useAccResultatsWidget