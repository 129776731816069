<template>
  <BaseWidget
      :title-object="{title: 'Résultats aux championnats du monde'}"
      :is-loading="isLoading"
      :front-error="hasError"
      :api-error="hasApiData"
  >
    <!-- RÉSULTATS -->
    <div class="flex flex-col gap-y-4">
      <div
          v-for="result in data()"
          :key="result.id"
          class="flex flex-col gap-y-2"
      >
        <span class="text-darkGrey text-center uppercase">{{ result.name }}</span>
        <div class="flex gap-2 items-end justify-center flex-wrap">
          <SquareIndicator
              v-for="indicateur in result.competitions[0].indicateurs"
              :key="indicateur.id"
              :value="indicateur.value"
              :label="indicateur.name"
          />
        </div>
      </div>
    </div>
  </BaseWidget>
</template>
                  
<script setup>

import useAccResultatsWidget from "@/composables/widgets/useAccResultatsWidget";
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
import {computed} from "vue";
import SquareIndicator from "@/components/common/SquareIndicator.vue";

const { loaded, data, error } = useAccResultatsWidget()
const isLoading = computed(() => !loaded || !loaded.value)
const hasError = computed(() => !!error && !!error.value)
const hasApiData = computed(() => !!data && data.value !== null && data.value !== undefined)

</script>