<template>
  <div v-for="(result, index) in data()" :key="index" v-show="!!result" class="flex flex-col gap-4">
    <BaseWidget
      v-if="!!result"
      :title-object="{title: result.name, alignLeft: true}"
      :toggle-object="{
        text: 'vue détaillée',
        checked: isDetailOpen(result.id),
        onClick: () => toggleDetail(result.id),
        absolutePosition: true,
      }"
      :is-loading="isLoading"
      :front-error="hasError"
      :api-error="result.length > 0"
    >
      <!-- FILTERS -->
      <div v-show="TAGS(result).length > 0" class="flex gap-x-1 gap-y-1 wrap -mt-2">
        <FilterLabel
          v-for="(tag, index) in TAGS(result)"
          :key="index"
          :label="`${tag.name}${!!tag.sexe ? ' - ' + tag.sexe : ''}`"
          :remove="() => handleRemoveTag(tag)"
        />
      </div>

      <!-- RESULTS -->
      <div
          class="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:gap-8 2xl:gap-x-12"
          :class="{
            '!grid-cols-1': isDetailOpen(result.id) && selectedIndicateurs.length > 2 || selectedCompetitions.length === 1,
            '2xl:!grid-cols-2': isDetailOpen(result.id) && selectedCompetitions.length >= 2 && selectedIndicateurs.length < 6,
          }"
      >
        <div
            v-for="competition in result.competitions"
            :key="competition.id"
            class="flex flex-col gap-y-2 items-center"

        >
          <span class="text-darkGrey text-center">{{ competition.name }}</span>
          <!-- DEFAULT VIEW -->
          <div
            v-if="!isDetailOpen(result.id)"
            class="grid grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-2"
            :class="{
              '!grid-cols-1': selectedIndicateurs.length === 1,
              '!grid-cols-2': selectedIndicateurs.length === 2,
              'md:!grid-cols-3': selectedIndicateurs.length === 3,
              '2xl:!grid-cols-4': selectedIndicateurs.length === 4,
              'md:!grid-cols-4': selectedCompetitions.length === 1 && selectedIndicateurs.length === 4,
              'md:!grid-cols-5': selectedCompetitions.length === 1 && selectedIndicateurs.length === 5,
              'md:!grid-cols-6': selectedCompetitions.length === 1 && selectedIndicateurs.length === 6,
              'md:!grid-cols-7': selectedCompetitions.length === 1 && selectedIndicateurs.length === 7,
              'md:!grid-cols-8': selectedCompetitions.length === 1 && selectedIndicateurs.length >= 8,
              'lg:!grid-cols-9': selectedCompetitions.length === 1 && selectedIndicateurs.length >= 9,
            }"
          >
            <SquareIndicator
                v-for="indicateur in competition.indicateurs.filter(
                    (i) => isIndicatorSelected(i)
                )"
                :key="indicateur.id"
                :value="indicateur.value"
                :label="indicateur.name"
            />
          </div>
          <!-- DETAILED VIEW -->
          <div v-else class="max-w-full overflow-x-auto overflow-y-hidden">
            <table class="border-collapse rounded-xl overflow-hidden table-fixed">
              <thead>
              <tr class="bg-lightestBlue border-b-2 border-b-white">
                <td class="border-2 border-white"/>
                <td
                  v-for="indicateur in competition.indicateurs.filter((i) => isIndicatorSelected(i))"
                  :key="indicateur.id"
                  class="text-sm inter-medium p-2 min-w-20 max-w-20"
                >
                  <span class="flex justify-center">{{ indicateur.name }}</span>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="row in competition.details"
                  :key="row.bateauLibelle + ' - ' + row.epreuveSexe"
                  @click="openResultsInNewTab(row.detail)"
                  class="bg-lightestBlue hover:bg-lighterBlue cursor-pointer"
              >
                <td class="text-left text-sm inter-medium p-2 border-2 border-white min-w-28 max-w-28">
                  {{ row.bateauLibelle }} - {{ row.epreuveSexe }}
                </td>
                <td
                    v-for="indicateur in competition.indicateurs.filter((i) => isIndicatorSelected(i))"
                    :key="indicateur.id"
                    class="border-b-2 border-b-white"
                >
                    <span class="flex flex-nowrap items-center justify-end gap-x-1 p-2">
                      <span
                          class="flex w-2 h-2 rounded-full"
                          :class="{
                            'bg-gold': indicateur.name === 'Or' && row[indicateur.id] > 0,
                            'bg-silver': indicateur.name === 'Argent' && row[indicateur.id] > 0,
                            'bg-bronze': indicateur.name === 'Bronze' && row[indicateur.id] > 0,
                          }"
                      />
                      <span v-if="indicateur.name === 'Argent' && row[indicateur.id]>0" class="medal-color medal-silver"></span>
                      <span v-if="indicateur.name === 'Bronze' && row[indicateur.id]>0" class="medal-color medal-bronze"></span>
                      <span>{{ row[indicateur.id] }}</span>
                    </span>
                </td>
              </tr>
              </tbody>

              <tfoot class="bg-lightestBlue hover:bg-lighterBlue">
              <tr class="text-base inter-bold">
                <td class="text-left p-2 border-2 border-white">Total</td>
                <td
                    v-for="indicateur in competition.indicateurs.filter((i) => isIndicatorSelected(i))"
                    :key="indicateur.id"
                >
                  <span class="flex justify-end p-2">{{ indicateur.value }}</span>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </BaseWidget>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4" :class="{ hidden: index !== 0 }">
      <ImageComponent class="max-sm:hidden !h-60" src="https://media.ffvoile.fr/uploads/images/fullscreen/230708_SE_Paris24_TestEvent_373513_7737335c.jpg" />
      <ImageComponent class="max-sm:hidden !h-60" src="https://media.ffvoile.fr/uploads/images/fullscreen/230715_SE_Paris24_TestEvent_5634_3738a21e.jpg" />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4" :class="{ hidden: index === 0 }">
      <ImageComponent class="max-sm:hidden !h-60 bg-top" src="https://media.ffvoile.fr/uploads/images/fullscreen/DSC034222c77.jpg" />
      <ImageComponent class="max-sm:hidden !h-60" src="https://media.ffvoile.fr/uploads/images/fullscreen/championnat_dde_France_Handivoile_a_Sciez_photos_Jean_Louis_Duzert_B_39170_15e36.jpg" />
    </div>
  </div>
</template>
                  
<script setup>
import useResultatWidget from '@/composables/widgets/useResultatWidget'
import { resultatFilter } from '@/composables/useFilters'
import SquareIndicator from "@/components/common/SquareIndicator.vue";
import FilterLabel from "@/components/common/FilterLabel.vue";
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
import {computed, toRef} from "vue";
import ImageComponent from "@/components/common/ImageComponent.vue";

const { loaded, data, error } = useResultatWidget()
const {
  selectedSeries, removeSerie, confirmSeriesSelection,
  selectedCompetitions,
  selectedIndicateurs, isIndicatorSelected,
  resetAge, isAllAgeSelected, getFilterAgeTag, confirmAgeSelection,
  collectifs, selectedCollectifs, selectCollectif, confirmCollectifSelection, resetCollectifs,
} = resultatFilter

const isLoading = computed(() => !loaded || !loaded.value)
const hasError = computed(() => !!error && !!error.value)

const detailsOpen = toRef({
  VO: false,
  DHN: false,
})

const toggleDetail = (resultId) => {
  if (resultId)
    detailsOpen.value[resultId] = !detailsOpen.value[resultId]
}

const isDetailOpen = (resultId) => {
  if (resultId)
    return detailsOpen.value[resultId]
}

const openResultsInNewTab = (details) => {
  const parsedDetails = JSON.parse(details);

  parsedDetails.forEach((d, index) => {
    setTimeout(() => {
      const url = `https://www.ffvoile.fr/ffv/sportif/ClmtCompetDet.asp?clid=${d.RE_ID}`;
      window.open(url, '_blank');
    }, index * 100);
  });
}

// Tags
function TAGS(result) {

  const tags = []
  const filtered = selectedSeries.value.filter((el) => el.raw.familleId === result.id)


  // series tag
  if (filtered.length === 1 && filtered.at(0).all)
  tags.push({ name: `Toutes les séries`, onDelete: () => {}})
  else
  filtered.forEach((el) => tags.push({
    name: el.name,
    sexe: el.sexe,
    onDelete: () => {
      removeSerie(el)
      confirmSeriesSelection()
    } }))

  // age tag
  if (!isAllAgeSelected()) {
    tags.push({
      name: getFilterAgeTag(),
      onDelete: () => {
        resetAge()
          confirmAgeSelection()
        }
      })
  }

  // collectifs tags
  if(selectedCollectifs.value.length === collectifs.value.length)
  tags.push({ name: "Tous les collectifs", onDelete: resetCollectifs })
  else
  selectedCollectifs.value.forEach((el) => tags.push({
    name: el.collectif,
    onDelete: () => {
      selectCollectif(el)
      confirmCollectifSelection()
    } }))

  return tags
}

function handleRemoveTag(tag) {
  tag.onDelete()
}

</script>