import Api from '@/composables/useApi'
import {ref, watch, onMounted, onUnmounted, computed} from 'vue'
import { yearFilter, regionFilter, clubFilter, resultatFilter } from '@/composables/useFilters'

const {
  selectedSeries,
  selectedCompetitions,
  getSortedSelectedCompetitions,
  selectedMinAge,
  selectedMaxAge,
  selectedCollectifs,
  indicateurs
} = resultatFilter

const useResultatWidget = () => {

  const voLoaded = ref(false)
  const dhnLoaded = ref(false)
  const watcherList = ref([])
  const voData = ref([])
  const hnData = ref([])
  const voAbortController = ref()
  const dhnAbortController = ref()
  const data = () => [voData.value, hnData.value]

  onMounted(async () => {
    const watchers = [
      yearFilter.singleYearFilter,
      regionFilter.selectedLigue,
      clubFilter.selectedPPFStructure,
      selectedSeries,
      selectedCompetitions,
      selectedMinAge,
      selectedMaxAge,
      selectedCollectifs
    ]

    watchers.forEach((target) => {
      const watcher = watch(target, (target, oldTarget) => { if(target !== oldTarget) fetchResultats() })
      watcherList.value.push(watcher)
    })

    fetchResultats()
  })

  onUnmounted(() => {
    watcherList.value.forEach((unwatch) => unwatch())
  })

  function handleRequestBody(series) {
    const body = {
      series: {
        series : series.map((serie) => ({
          codeBateau: serie.raw.codeBateau || null,
          codeBateauLibell: serie.raw.codeBateauLibelle || null,
          disciplineFamille: serie.raw.familleId || null,
          disciplineCategorie: serie.raw.categorieId || null,
          secteurCode: serie.raw.secteurCode || null,
          sexe: serie.raw.sexe || null
        }))
      },
      competitions: {
        competitions: selectedCompetitions.value.length
        ? [selectedCompetitions.value.map((el) => el.id).join(',')]
        : []
      },
      annees: {
        anneeDebut: yearFilter.singleYearFilter.value,
        anneeFin: yearFilter.singleYearFilter.value,
      },
      ages: {
        ageMin: selectedMinAge.value || null,
        ageMax: selectedMaxAge.value || null
      },
    }

    if (selectedCollectifs.value.length) {
      body.collectifs = {
        collectifs: [selectedCollectifs.value.map((el) => el.tcolIds).join(',')]
      }
    }
    
    if(regionFilter.selectedLigue.value)
      body.ligue = {
        ligue: regionFilter.selectedLigue.value.id
      }

    if(clubFilter.selectedPPFStructure.value)
      body.structures = {
        structureCodes: [clubFilter.selectedPPFStructure.value.code]
      }
    return body
  }

  async function fetchResultats() {
    try {
      voLoaded.value = false
      dhnLoaded.value = false

      // empêche d'envoyer des requêtes à vide
      if(!selectedSeries.value.length || !selectedCompetitions.value.length) {
        voData.value = null
        hnData.value = null
        voLoaded.value = true
        dhnLoaded.value = true
        return
      }

      const voReq = selectedSeries.value.filter((el) => el.raw.familleId === "VO")
      if(voReq.length) {

        if (voAbortController.value) {
          await voAbortController.value.abort()
        }
        voAbortController.value = new AbortController()
        const voSignal = voAbortController.value.signal
        let voPromiseOk = true;

        const voResponse = await Api.post(
            '/stats/indicateursperformance/resultats',
            handleRequestBody(voReq),
            { signal: voSignal }
        ).catch(() => {
          voPromiseOk = false
        })
        if (voPromiseOk) {
          voData.value = handleResults("VO", "Voile Olympique", voResponse.data)
        }
      } else {
        voData.value = null
      }
      voLoaded.value = true
      
      const hnReq = selectedSeries.value.filter((el) => el.raw.familleId === "DHN")
      if(hnReq.length) {

        if (dhnAbortController.value) {
          await dhnAbortController.value.abort()
        }
        dhnAbortController.value = new AbortController()
        const dhnSignal = dhnAbortController.value.signal
        let dhnPromiseOk = true;

        const hnResponse = await Api.post(
            '/stats/indicateursperformance/resultats',
            handleRequestBody(hnReq),
            { signal: dhnSignal }
        ).catch(() => {
          dhnPromiseOk = false
        })

        if (dhnPromiseOk === true) {
          hnData.value = handleResults("DHN", "Disciplines de Haut Niveau", hnResponse.data)
        }
      } else {
        hnData.value = null
      }
      dhnLoaded.value = true
    }
    catch (err) {
      console.error(err)
    }
  }

  function handleResults(id, name, results) {
    const data = {
      id,
      name,
      competitions: [],
      details: []
    }

    data.competitions = getSortedSelectedCompetitions(id).map((compet) => {
      const targetCompet = results.filter((el) => el.hnNiveauId.trim() === compet.id.trim())

      return ({
        id: compet.id,
        name: compet.libelle,
        indicateurs: indicateurs.value.map((indic) => {

          const indicData = {
            id: indic.id,
            name: indic.name,
          }

          const indicatorValues = targetCompet.map((el) => el[indic.id])
          if(targetCompet.length) {
            if(indic.id === "meilleurePlace") {
              indicData.value = Math.min(...indicatorValues)
            }
            else {
              indicData.value = indicatorValues.reduce((acc, val) => acc + val, 0)
            }
          } else {
            indicData.value = null
          }
          return indicData
        }),
        details: targetCompet,
      })
    })
    return data
  }

  return {
    loaded: computed(() => voLoaded.value && dhnLoaded.value),
    data,
  }
}

export default useResultatWidget