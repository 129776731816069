/**
 * Crée l'app VueJS et pré-importe tous les composants
 * Enregistrer des composants globalement permet de ne pas avoir à les importer dans les vues et autres composants
 * Permet aussi de facilement gérer les urls d'imports si on décide de ranger les composants ailleurs
 *   • Doc -> https://vuejs.org/guide/components/registration.html#global-registration
 */

// --- Import des composants ----------------------------------------

/**
 * Commun
 */

// Layouts
import GlobalLayout from "@/components/layouts/GlobalLayout.vue";


// UI
import HeaderComponent from '@/components/common/HeaderComponent.vue'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import TricolorBanner from '@/components/common/TricolorBanner.vue'
import FlipButton from '@/components/common/FlipButton.vue'
import FilterSimple from "@/components/common/FilterSimple.vue";
import CheckboxComponent from "@/components/common/CheckboxComponent.vue";
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import ResetFilterButton from "@/components/common/ResetFilterButton.vue";
import FilterLabel from "@/components/common/FilterLabel.vue";
import FilterComponent from "@/components/common/FilterComponent.vue";
import ToggleComponent from "@/components/common/ToggleComponent.vue";
import SerieFilterCategorie
  from "@/components/pages/haute-performance/filtres/resultat-filter/SerieFilterCategorie.vue";
import RadioButton from "@/components/common/RadioButton.vue";

// Charts
import VerticalBarChart from '@/components/widgets/charts/VerticalBarChart.vue'
import HorizontalBarChart from '@/components/widgets/charts/HorizontalBarChart.vue'
import DoughnutChart from '@/components/widgets/charts/DoughnutChart.vue'

// Widget templates
import VerticalBarWidget from '@/components/widgets/templates/VerticalBarWidget.vue'
import HorizontalBarWidget from '@/components/widgets/templates/HorizontalBarWidget.vue'
import DonutWidget from '@/components/widgets/templates/DonutWidget.vue'
import ProgressWidget from '@/components/widgets/templates/ProgressWidget.vue'
import SquareIndicatorsWidget from '@/components/widgets/templates/SquareIndicatorsWidget.vue'

/**
 * Page Développement
 */

// Filtres
import AgeFilter from '@/components/pages/developpement/filtres/AgeFilter.vue'
import LicenceFilter from '@/components/pages/developpement/filtres/LicenceFilter.vue'
import GenreFilter from '@/components/pages/developpement/filtres/GenreFilter.vue'
import LigueFilter from "@/components/globalFilters/LigueFilter.vue";
import StructureFilter from "@/components/globalFilters/StructureFilter.vue";
import DateFilter from "@/components/globalFilters/DateFilter.vue";

// Widgets
import BenevolesWidget from '@/components/pages/developpement/widgets/BenevolesWidget.vue'
import ContratsWidget from '@/components/pages/developpement/widgets/ContratsWidget.vue'
import DirigeantsWidget from '@/components/pages/developpement/widgets/DirigeantsWidget.vue'
import FullTimeWidget from '@/components/pages/developpement/widgets/FullTimeWidget.vue'
import AccueilWidget from '@/components/pages/developpement/widgets/AccueilWidget.vue'
import RevenueWidget from '@/components/pages/developpement/widgets/RevenueWidget.vue'
import StructureWidget from '@/components/pages/developpement/widgets/StructureWidget.vue'
import PlandeauWidget from '@/components/pages/developpement/widgets/PlandeauWidget.vue'
import MembreWidget from '@/components/pages/developpement/widgets/MembreWidget.vue'
import FullLicenceWidget from '@/components/pages/developpement/widgets/FullLicenceWidget.vue'
import RenouvellementWidget from '@/components/pages/developpement/widgets/licence-widget/RenouvellementWidget.vue'
import RepartitionGenreWidget from '@/components/pages/developpement/widgets/licence-widget/RepartitionGenreWidget.vue'
import RepartitionAgeWidget from '@/components/pages/developpement/widgets/licence-widget/RepartitionAgeWidget.vue'
import LicenceTotalWidget from '@/components/pages/developpement/widgets/licence-widget/LicenceTotalWidget.vue'
import LabelsNatWidget from '@/components/pages/developpement/widgets/LabelsNatWidget.vue'

/**
 * Page Haute Performance
 */

// Filtres
import HPAgeFilter from '@/components/pages/haute-performance/filtres/resultat-filter/AgeFilter.vue'
import ResultatFilter from '@/components/pages/haute-performance/filtres/ResultatFilter.vue'
import SerieFilter from '@/components/pages/haute-performance/filtres/resultat-filter/SerieFilter.vue'
import CompetitionFilter from '@/components/pages/haute-performance/filtres/resultat-filter/CompetitionFilter.vue'
import IndicateurFilter from '@/components/pages/haute-performance/filtres/resultat-filter/IndicateurFilter.vue'
import CollectifFilter from '@/components/pages/haute-performance/filtres/resultat-filter/CollectifFilter.vue'
import StructureButtonFilter from '@/components/pages/haute-performance/filtres/StructureButtonFilter.vue'

// Widgets
import ResultatWidget from '@/components/pages/haute-performance/widgets/ResultatWidget.vue'
import ProjetPerfWidget from '@/components/pages/haute-performance/widgets/ProjetPerfWidget.vue'
import AlimentationListeHNWidget from '@/components/pages/haute-performance/widgets/AlimentationListeHNWidget.vue'
import AlimentationCollectifsWidget from '@/components/pages/haute-performance/widgets/AlimentationCollectifsWidget.vue'

import AlimentationStructureNiveauSupWidget from '@/components/pages/haute-performance/widgets/AlimentationStructureNiveauSupWidget.vue'

/**
 * Page Pratiques Sportives
 */

// Filtres

import PSGenreFilter from '@/components/pages/pratiques-sportives/filtres/PSGenreFilter.vue'
import PSPratiqueFilter from '@/components/pages/pratiques-sportives/filtres/PSPratiqueFilter.vue'
import PSAgeFilter from '@/components/pages/pratiques-sportives/filtres/PSAgeFilter.vue'

// Widgets
import PSNombreLicenciesClassesWidget from '@/components/pages/pratiques-sportives/widgets/PSNombreLicenciesClassesWidget.vue'
import PSNombreEpreuvresCouruesWidget from '@/components/pages/pratiques-sportives/widgets/PSNombreEpreuvresCouruesWidget.vue'
import PSRepartitionClassesWidget from '@/components/pages/pratiques-sportives/widgets/PSRepartitionClassesWidget.vue'
import PSFidelisationClassesWidget from '@/components/pages/pratiques-sportives/widgets/PSFidelisationClassesWidget.vue'
import PSFidelisationWidget from '@/components/pages/pratiques-sportives/widgets/classes-widget/PSFidelisationWidget.vue'
import PSNouveauxClassesWidget from '@/components/pages/pratiques-sportives/widgets/classes-widget/PSNouveauxClassesWidget.vue'
import PSClassesInterseriesWidget from '@/components/pages/pratiques-sportives/widgets/PSClassesInterseriesWidget.vue'
import PSNbrEpreuvesOrganiseesWidget from '@/components/pages/pratiques-sportives/widgets/PSNbrEpreuvesOrganiseesWidget.vue'
import PSRepartitionGradeWidget from '@/components/pages/pratiques-sportives/widgets/PSRepartitionGradeWidget.vue'
import PSRepartitionClassesGenreWidget from '@/components/pages/pratiques-sportives/widgets/repartition-classes/PSRepartitionClassesGenreWidget.vue'
import PSRepartitionClassesAgeWidget from '@/components/pages/pratiques-sportives/widgets/repartition-classes/PSRepartitionClassesAgeWidget.vue'





// --- Création de l'app VueJS ----------------------------------------

import App from './App.vue'
import { createApp } from 'vue'

const app = createApp(App)

// Ajout d'une directive pour gérer les clics en dehors des éléments
app.directive('click-outside', {
  mounted: (el, binding) => {
    el.clickOutsideEvent = event => {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
})

// --- Enregistrement des composants dans l'app -----------------------

const COMPONENTS = [
  // Layouts
  { name: 'GlobalLayout', component: GlobalLayout },

  // Global Filters
  { name: 'StructureFilter', component: StructureFilter },
  { name: 'LigueFilter', component: LigueFilter },
  { name: 'DateFilter', component: DateFilter },

  // Commun
  // elements
  { name: 'HeaderComponent', component: HeaderComponent },
  { name: 'LoadingSpinner', component: LoadingSpinner },
  { name: 'FlipButton', component: FlipButton },
  { name: 'TricolorBanner', component: TricolorBanner },
  { name: 'ButtonComponent', component: ButtonComponent },
  { name: 'FilterComponent', component: FilterComponent },
  { name: 'FilterSimple', component: FilterSimple },
  { name: 'FilterLabel', component: FilterLabel },
  { name: 'CheckboxComponent', component: CheckboxComponent },
  { name: 'ResetFilterButton', component: ResetFilterButton },
  { name: 'ToggleComponent', component: ToggleComponent },
  { name: 'RadioButton', component: RadioButton },

  // charts
  { name: 'VerticalBarChart', component: VerticalBarChart },
  { name: 'HorizontalBarChart', component: HorizontalBarChart },
  { name: 'DoughnutChart', component: DoughnutChart },

  // widget templates
  { name: 'VerticalBarWidget', component: VerticalBarWidget },
  { name: 'HorizontalBarWidget', component: HorizontalBarWidget },
  { name: 'DonutWidget', component: DonutWidget },
  { name: 'ProgressWidget', component: ProgressWidget },
  { name: 'SquareIndicatorsWidget', component: SquareIndicatorsWidget },

  // Développement
  // filters
  { name: 'AgeFilter', component: AgeFilter },
  { name: 'LicenceFilter', component: LicenceFilter },
  { name: 'GenreFilter', component: GenreFilter },

  // widgets
  { name: 'BenevolesWidget', component: BenevolesWidget },
  { name: 'ContratsWidget', component: ContratsWidget },
  { name: 'DirigeantsWidget', component: DirigeantsWidget },
  { name: 'FullTimeWidget', component: FullTimeWidget },
  { name: 'AccueilWidget', component: AccueilWidget },
  { name: 'RevenueWidget', component: RevenueWidget },
  { name: 'StructureWidget', component: StructureWidget },
  { name: 'PlandeauWidget', component: PlandeauWidget },
  { name: 'MembreWidget', component: MembreWidget },
  { name: 'FullLicenceWidget', component: FullLicenceWidget },
  { name: 'RenouvellementWidget', component: RenouvellementWidget },
  { name: 'RepartitionGenreWidget', component: RepartitionGenreWidget },
  { name: 'RepartitionAgeWidget', component: RepartitionAgeWidget },
  { name: 'LicenceTotalWidget', component: LicenceTotalWidget },
  { name: 'LabelsNatWidget', component: LabelsNatWidget },


  // Haute Performance

  // filters
  { name: 'StructureButtonFilter', component: StructureButtonFilter },
  { name: 'HPAgeFilter', component: HPAgeFilter },
  { name: 'ResultatFilter', component: ResultatFilter },
  { name: 'SerieFilter', component: SerieFilter },
  { name: 'SerieFilterCategorie', component: SerieFilterCategorie },
  { name: 'CompetitionFilter', component: CompetitionFilter },
  { name: 'IndicateurFilter', component: IndicateurFilter },
  { name: 'CollectifFilter', component: CollectifFilter },

  // widgets
  { name: 'ResultatWidget', component: ResultatWidget },
  { name: 'ProjetPerfWidget', component: ProjetPerfWidget },
  { name: 'AlimentationListeHNWidget', component: AlimentationListeHNWidget },
  { name: 'AlimentationCollectifsWidget', component: AlimentationCollectifsWidget },
  
  { name: 'AlimentationStructureNiveauSupWidget', component : AlimentationStructureNiveauSupWidget},



  // Pratiques Sportives :
  // filters
  { name: 'PSGenreFilter', component: PSGenreFilter },
  { name: 'PSAgeFilter', component: PSAgeFilter },
  { name: 'PSPratiqueFilter', component: PSPratiqueFilter },

  // widgets
  { name: 'PSNombreLicenciesClassesWidget', component: PSNombreLicenciesClassesWidget },
  { name: 'PSNombreEpreuvresCouruesWidget', component: PSNombreEpreuvresCouruesWidget },
  { name: 'PSRepartitionClassesWidget', component: PSRepartitionClassesWidget },
  { name: 'PSFidelisationClassesWidget', component: PSFidelisationClassesWidget },
  { name: 'PSFidelisationWidget', component: PSFidelisationWidget },
  { name: 'PSNouveauxClassesWidget', component: PSNouveauxClassesWidget },
  { name: 'PSClassesInterseriesWidget', component: PSClassesInterseriesWidget },
  { name: 'PSNbrEpreuvesOrganiseesWidget', component: PSNbrEpreuvesOrganiseesWidget },
  { name: 'PSRepartitionGradeWidget', component: PSRepartitionGradeWidget },
  { name: 'PSRepartitionClassesGenreWidget', component: PSRepartitionClassesGenreWidget },
  { name: 'PSRepartitionClassesAgeWidget', component: PSRepartitionClassesAgeWidget },
]

COMPONENTS.forEach((data) => app.component(data.name, data.component))

// --- Export de l'app pour main.js ----------------------------------------

export default app
